import { fetchJSON } from '@bonnet/core/fetch';

export default (query = {}) => fetchJSON(
    '/rest/lsc/listing/similar',
    {
        circuitBreaker: {
            id: 'similarlistings',
            timeout: 3000,
            resetTimeout: 300000,
            fallback: {},
        },
        query,
        headers: {
            'X-fwd-svc': 'atc',
        },
    }
);
