import _get from 'lodash/get';

import { fetchJSON } from '@bonnet/next/fetch';

import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { vdpDuckPreset } from '@/ducks/presets';

const JR2 = 'jcr:content/par/dealerdiff.2.json';
const FETCH_OPTIONS = {
    circuitBreaker: {
        id: 'dealerdiffInfo',
        timeout: 5000,
        resetTimeout: 300000,
        fallback: {},
    },
};

const HTML_FETCH_OPTIONS = {
    circuitBreaker: {
        id: 'dealerDiffHtml',
        timeout: 5000,
        resetTimeout: 300000,
        fallback: {},
    },
};

const vdpDealerDiffDuck = objectDuckCreator({
    ...vdpDuckPreset,
    store: 'dealerDiff',
    initialState: {
        content: {},
    },
}).extend({
    creators: (duck) => ({
        loadDealerDiff: (query, aemHost) => async (dispatch) => {
            // TODO: the proxy should be removed and replace with new content endpoint instead
            const infoEndpoint = `/cars-for-sale/content/autotrader/dealer_diff/${query.dealerDiffCmsId}/autotrader/${query.listingType}/${JR2}`;
            const htmlEndpoint = `${aemHost}/content/autotrader/dealer_diff/${query.dealerDiffCmsId}/autotrader/${query.listingType}.html`;

            const data = (await fetchJSON(infoEndpoint, FETCH_OPTIONS)) || {};
            const rawHtmlData = (await fetch(htmlEndpoint, HTML_FETCH_OPTIONS).then((res) => res.text())) || '';

            // included css will start with relative path / and pointed to current domain instead of content[-preprod].autotrader.com, find any not start aemhost and replace it
            const htmlContent = rawHtmlData.replace(/href=(["'])(?!(.*${aemHost}))(.+)(["'])/g, `href=$1${aemHost}$2$3$4`);
            const cta = {
                label: data.ctaLinkText || '',
                offsite: data.ctalinkInternalExternal || '',
                link: data.ctaLinkUrl || '',
            };
            const logo = {
                src: `${aemHost}/${_get(data, 'logoImage.fileReference', '')}`,
            };

            dispatch(duck.creators.setKeys({
                content: {
                    cta,
                    htmlContent,
                    logo,
                },
            }));
        },
    }),
    selectors: () => ({
        getContent: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).content),
    }),
});

export default vdpDealerDiffDuck;
