import {
    psxAutoGapInsuranceClick,
    psxBuyerGuideClick,
    psxEmailUsClick,
    psxEscrowClick,
    psxFAQsClick,
    psxLearnMoreBuyersGuideClick,
    psxLearnMoreFaqsClick,
    psxLearnMoreLinkClick,
    psxLearnMoreSellersGuideClick,
    psxMakeOfferClick,
    psxManufactureWarrantyClick,
    psxManufactureWarrantyImpression,
    psxOptionAddOnProtectionsClick,
    psxOptionAddOnProtectionsImpression,
    psxPreQualifyClick,
    psxPrivateSellerCarsClick,
    psxPrivateSellerExchangeLogoClick,
    psxSellerGuideClick,
    psxSellMyCarLinkClick,
    psxSellYourCarLinkClick,
    psxStripeClick,
    psxVDPBuyerGuideClick,
    psxVDPBuyNowClick,
    psxVDPChatSellerClick,
    psxVDPEmailSubscribeClick,
    psxVDPEstimateDeliveryCostZipSubmit,
    psxVDPEstimateYourMonthlyPaymentClick,
    psxVDPFaqClick,
    psxVDPFinanceClick,
    psxVDPGapInsuranceClick,
    psxVDPGetPreQualifyClick,
    psxVDPMyDashboardClick,
    psxVDPOptionDetailsAccordionClick,
    psxVDPOtherEquipmentAccordionClick,
    psxVDPPageLoadEvent,
    psxVDPStandardEquipmentAccordionClick,
    psxVDPTestDriveClick,
    psxVDPVehicleProtectionGAPProtectionClick,
    psxVDPVehicleProtectionVehicleServiceContractsClick,
    psxVDPVehicleServiceContractsClick,
    psxVDPVHRClick,
    psxVDPVHRImpression,
    psxVehicleProtectionClick,
    psxVideoComplete,
    psxVideoStart,
    psxWarrantyClick,
} from 'reaxl-analytics';

export default {
    psxVideoStart,
    psxVideoComplete,
    psxPrivateSellerCarsClick,
    psxSellMyCarLinkClick,
    psxPreQualifyClick,
    psxWarrantyClick,
    psxEscrowClick,
    psxAutoGapInsuranceClick,
    psxVehicleProtectionClick,
    psxBuyerGuideClick,
    psxSellerGuideClick,
    psxFAQsClick,
    psxStripeClick,
    psxPrivateSellerExchangeLogoClick,
    psxVDPPageLoadEvent,
    psxVDPBuyNowClick,
    psxVDPTestDriveClick,
    psxVDPChatSellerClick,
    psxMakeOfferClick,
    psxVDPGetPreQualifyClick,
    psxVDPBuyerGuideClick,
    psxVDPEmailSubscribeClick,
    psxVDPVHRClick,
    psxVDPVHRImpression,
    psxVDPOptionDetailsAccordionClick,
    psxVDPStandardEquipmentAccordionClick,
    psxVDPOtherEquipmentAccordionClick,
    psxVDPEstimateYourMonthlyPaymentClick,
    psxVDPVehicleProtectionVehicleServiceContractsClick,
    psxVDPVehicleProtectionGAPProtectionClick,
    psxVDPFaqClick,
    psxLearnMoreFaqsClick,
    psxLearnMoreBuyersGuideClick,
    psxLearnMoreSellersGuideClick,
    psxVDPFinanceClick,
    psxVDPVehicleServiceContractsClick,
    psxVDPGapInsuranceClick,
    psxVDPEstimateDeliveryCostZipSubmit,
    psxVDPMyDashboardClick,
    psxOptionAddOnProtectionsImpression,
    psxOptionAddOnProtectionsClick,
    psxManufactureWarrantyImpression,
    psxManufactureWarrantyClick,
    psxEmailUsClick,
    psxLearnMoreLinkClick,
    psxSellYourCarLinkClick,
};
