import React, { memo } from 'react';

import PropTypes from 'prop-types';

import {
    AdSlot,
} from 'reaxl-ads';

const AtcAdSlot = memo(({
    advertisementLabel,
    showLabel,
    showPlaceholder,
    isGlobalAdsPlaceholderOff,
    slotId,
    ...rest
}) => (
    <AdSlot
        advertisementLabel={showLabel ? advertisementLabel : { className: 'hidden' }}
        extra={{
            'data-qa-data': JSON.stringify({ ...rest, slotId }),
        }}
        showPlaceholder={showPlaceholder}
        slotId={slotId}
        {...rest}
    />
));

AtcAdSlot.propTypes = {
    advertisementLabel: PropTypes.object,
    className: PropTypes.string,
    extra: PropTypes.object,
    lazyLoading: PropTypes.bool,
    lazyLoadingBottomOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lazyLoadingTopOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showLabel: PropTypes.bool,
    size: PropTypes.array.isRequired,
    slotId: PropTypes.string.isRequired,
    showPlaceholder: PropTypes.bool,
};

AtcAdSlot.defaultProps = {
    advertisementLabel: {
        className: 'text-right text-size-5',
        text: 'Advertisement',
    },
    className: 'margin-horizontal-auto margin-bottom-4',
    //  Using 300 for offsets to mirror LazyRender
    lazyLoadingBottomOffset: '-300',
    lazyLoadingTopOffset: '-300',
    showLabel: true,
    showPlaceholder: true,
};

export default AtcAdSlot;
