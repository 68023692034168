import _get from 'lodash/get';

export function getDealerDetailUrl(owner) {

    const { zip, state, city } = _get(owner, 'location.address', {});

    if (zip && state && city && owner.id) {
        // Ensures we only pass a 5 digit zipCode
        const zipCode = zip.slice(0, 5);
        // Removes spaces and special characters from city name and owner names
        const cityName = city.replace(/[^A-Za-z0-9\s]/gi, '').replace(/[\s-]+/g, '+');
        const urlOwnerName = owner.name.replace(/[^A-Za-z0-9\s]/gi, ' ').replace(/\s\s+/g, ' ').trim().split(' ')
            .join('+');
        const href = '/car-dealers/' + cityName + '+' + state + '-' + zipCode + '/' + owner.id + '/' + urlOwnerName + '?'
                        + 'dealer_id=' + owner.id;
        return {
            href: href.toLowerCase(),
        };
    }
    return null;
}

export default getDealerDetailUrl;
