import {
    drDealSubmissions,
    ndsAutoFill,
    ndsCalcCtaClick,
    ndsCalcInteraction,
    ndsDealerFinancingCTA,
    ndsEligibleImpression,
    ndsEsntialBuyOnlineClick,
    ndsGetPreQualifiedExploreFinancingClick,
    ndsInsuranceSponsorCtaClick,
    ndsInsuranceSponsorInviewImpression,
    ndsInviewImpression,
    ndsKBBTradeInCTAClick,
    ndsLendingOptionsCTAClick,
    ndsNavigationClick,
    ndsOfferBreakDownClick,
    ndsPayoffQuoteCTAClick,
    ndsPencilingEvents,
    ndsStartCalculatorImpression,
    sdpRequestAppointment,
    startCreditApplication,
    submitOfferButtonClick,
} from 'reaxl-analytics';

import {
    userDuck,
} from '@/ducks';

export default {
    ndsAutoFill,
    ndsCalcCtaClick,
    ndsCalcInteraction: (metadata = {}, option) => ndsCalcInteraction({
        ...metadata,
        userDuck,
    }, option),
    ndsPencilingEvents: (metadata = {}, option) => ndsPencilingEvents({
        ...metadata,
        userDuck,
    }, option),
    ndsDealerFinancingCTA,
    ndsInviewImpression,
    ndsInsuranceSponsorInviewImpression,
    ndsInsuranceSponsorCtaClick,
    ndsEligibleImpression,
    ndsNavigationClick,
    ndsEsntialBuyOnlineClick,
    ndsLendingOptionsCTAClick,
    ndsGetPreQualifiedExploreFinancingClick,
    ndsOfferBreakDownClick,
    ndsKBBTradeInCTAClick,
    ndsStartCalculatorImpression,
    ndsPayoffQuoteCTAClick,
    sdpRequestAppointment,
    submitOfferButtonClick,
    startCreditApplication,
    drDealSubmissions,
};
