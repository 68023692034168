import { useEffect } from 'react';

import { useCookies } from 'react-cookie';

import _get from 'lodash/get';

/*
 * cookieName & cookieValue required
 * options: https://www.npmjs.com/package/react-cookies
 * extendOnPageLoad will recreate the cookie everytime the hook is called if the cookie already exists
 */
export function useCookie(cookieName, cookieValue, options, extendOnPageLoad) {
    const [cookies, setCookie] = useCookies([cookieName]);
    const cookiesOptions = {
        path: '/',
        ...options,
    };

    /*
     * If cookie should reset on pageLoad, either reset with cookie value passed in or look up current cookie value for
     * specified cookie and reset it
     * If cookie should not reset on pageLoad and a valid cookie value is passed in and the cookie doesn't already exist
     * and it doesn't exist with current value being passed in, then set it
     */
    useEffect(() => {
        if (extendOnPageLoad && _get(cookies, `${cookieName}`) !== undefined) {
            const newCookieValue = cookieValue || _get(cookies, `${cookieName}`);
            setCookie(cookieName, newCookieValue, cookiesOptions);
        } else if (cookieValue && (_get(cookies, `${cookieName}`) === undefined || _get(cookies, `${cookieName}`) !== cookieValue)) {
            setCookie(cookieName, cookieValue, cookiesOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookieName, cookieValue, cookies, setCookie, options, extendOnPageLoad]);
}

export default useCookie;
