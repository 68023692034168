import AlphaModuleCreator from '@/moduleCreators/AlphaModuleCreator';

import { srpDuckPreset } from '@/ducks/presets';

const alphaModule = AlphaModuleCreator().extend({
    duck: srpDuckPreset,
    ctxMiddleware: ({ ctxMiddleware }) => async (ctx) => {
        const [isAlphaEnabled] = ctx.useFeature('alpha_showcase');

        if (isAlphaEnabled) {
            // call the original ctx-middleware and pass it the ctx;
            await ctxMiddleware(ctx);
        }
    },
});

export default alphaModule;
