import _get from 'lodash/get';

import {
    compareListingsDrawerClick,
    compareSigninClick,
    inventoryCertifiedTileClick,
    inventoryClick,
    inventoryDealerDiffClick,
    inventoryImpressions,
    inventoryListingViewEllipsesClick,
    inventoryMediaViewerClick,
    inventoryProductClick,
    inventoryProductImpressions,
    inventorySave,
    inventoryScrollImpressions,
    inventoryVideoCompleted,
    inventoryVideoMilestones,
    inventoryVideoPlay,
    mediaGalleryNdsCTAClick,
    mediaTabClick,
    mediaViewerPagination,
    preorderInventoryImpressions,
    shareClick,
    viewAllMediaClick,
} from 'reaxl-analytics';

import { userDuck } from '@/ducks';

import { srpFiltersDuck } from '@/ducks/srp';
import { vdpResultsDuck } from '@/ducks/vdp';

export default {
    inventoryCertifiedTileClick: (metadata, option) => {
        const { data, state } = metadata;

        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);

        return inventoryCertifiedTileClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
                eventSource: 'vehicle',
            },
            userDuck,
        }, option);
    },
    inventoryClick: (metadata, option) => {
        const { data, state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);

        return inventoryClick({
            ...metadata,
            data: {
                ...data,
                eventSource: 'vehicle',
                pixallData: {
                    ...data.pixallData,
                    filterValues,
                },
            },
            userDuck,
        }, option);
    },
    inventoryImpressions: (metadata, option) => inventoryImpressions({
        ...metadata,
        userDuck,
    }, option),
    inventoryProductClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryProductClick({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
            userDuck,
        }, option);
    },

    inventorySave: (metadata, option) => inventorySave({
        ...metadata,
        userDuck,
    }, option),

    compareSigninClick: (metadata, option) => compareSigninClick({
        ...metadata,
    }, option),
    compareListingsDrawerClick: (metadata, option) => {
        const { data, state } = metadata;
        const { pixallData } = data;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        const zipCode = _get(userDuck.selectors.getZip(state));

        return compareListingsDrawerClick({
            ...metadata,
            pixallData: {
                ...pixallData,
                filterValues,
                zipCode,
            },
        }, option);
    },

    inventoryListingViewEllipsesClick: (metadata, option) => inventoryListingViewEllipsesClick({
        ...metadata,
        userDuck,
    }, option),

    inventoryProductImpressions: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryProductImpressions({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    inventoryDealerDiffClick: (metadata, option) => inventoryDealerDiffClick({
        ...metadata,
    }, option),
    inventoryScrollImpressions: (data, option) => inventoryScrollImpressions({
        ...data,
    }, option),

    preorderInventoryImpressions: (data, option) => preorderInventoryImpressions({
        ...data,
    }, option),
    inventoryMediaViewerClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryMediaViewerClick({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    viewAllMediaClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return viewAllMediaClick({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    mediaViewerPagination: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return mediaViewerPagination({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    mediaTabClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return mediaTabClick({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    inventoryVideoPlay: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryVideoPlay({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    inventoryVideoCompleted: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryVideoCompleted({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    inventoryVideoMilestones: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryVideoMilestones({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    mediaGalleryNdsCTAClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return mediaGalleryNdsCTAClick({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    shareClick: (metadata, option) => {
        // TODO on SRP GA is missing CD's 100 - 160
        const { data } = metadata;
        return shareClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: data?.id,
            },
            userDuck,
        }, option);
    },
};
