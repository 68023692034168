import qs from 'querystring';

const { window } = global;

export function query() {
    if (!window) {
        return {};
    }
    return qs.parse(window.location.search.slice(1));
}

// Removes the specified parameters from the page's query string
export function removeParameters(...args) {
    if (!window) {
        return {};
    }
    const parsedQS = query();

    // Allow removeParameters method to be called with a single array parameter `removeParameters(['a', 'b'])` or
    // a list of parameters `removeParameters('a', 'b')`
    const params = typeof args[0] === 'object' ? args[0] : args;

    params.forEach((param) => {
        delete parsedQS[param];
    });

    const newQueryString = qs.stringify(parsedQS);

    // Update browser URL to reflect parameter removal
    window.history.replaceState(
        window.history.state,
        '',
        window.location.pathname
            + (newQueryString ? '?' + newQueryString : '')
            + window.location.hash
    );

    // Return updated query object
    return parsedQS;
}

export default {
    query,
    removeParameters,
};
