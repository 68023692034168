// Transform payment services data based on feature flags.
import { batch } from 'react-redux';

import deepMerge from 'deepmerge';

import {
    inventoryDuck,
} from '@/ducks';

const overridePSUrlParameters = (url = '', overrideParams = {}, removeParams = [], useDefaultRates = false) => {
    const psSearchParams = new URLSearchParams(url);
    if (useDefaultRates && !psSearchParams.has('defaultRate')) {
        psSearchParams.append('defaultRate', true);
    }
    Object.entries(overrideParams).forEach(([key, value]) => {
        psSearchParams.set(key, value);
    });

    removeParams.forEach((params) => {
        psSearchParams.delete(params);
    });

    return psSearchParams.toString();
};

export default function withPaymentServices() {
    return async (ctx) => {
        const {
            brand: [, {
                paymentDealerId,
                paymentPartnerId,
            }],
            nds_calc: [, { forceDefaultRates }],
            nds_enable_lease: [isNdsLeaseEnabled, leaseEnabledDRTypes = {}],
            nds_enable_price_breakdown: [isPriceBreakdownEnabled, priceBkdwnEnabledDRTypes = {}],
            non_dt_payment_services: [enabledNonDTPaymentServices],
            use_fba_payment_calc: [enableFBAPaymentCalc],
        } = ctx.useFeatures(['brand', 'nds_calc', 'nds_enable_lease', 'nds_enable_price_breakdown', 'non_dt_payment_services', 'use_fba_payment_calc']);

        const state = ctx.store.getState();
        const inventory = inventoryDuck.selectors.getInventory(state);

        const inventoryUpdateActions = Object.values(inventory).map(({ id, paymentServices = {}, ownerName }) => {
            let { digitalRetailingType } = paymentServices;
            if (paymentServices.digitalRetailingType === 'accelerate') {
                // Treat non-migrated Accelerate listings as default
                if (!paymentServices.dealerSettings?.uiEnabled) {
                    digitalRetailingType = 'default';
                }
            }

            const leaseEnabled = !!paymentServices.dealerSettings?.leaseEnabled
                && isNdsLeaseEnabled
                && !!leaseEnabledDRTypes[`enable_${digitalRetailingType}`];

            const priceBreakdownEnabled = isPriceBreakdownEnabled
                && priceBkdwnEnabledDRTypes[`enable_${digitalRetailingType}`] || false;

            const overridePaymentCalcParams = {
                enableLease: leaseEnabled,
            };

            const overrideIncentivesParams = {};

            const removeParams = [];

            if (!priceBreakdownEnabled) {
                overridePaymentCalcParams.disableIncentives = true;
                overridePaymentCalcParams.hasSpecialOffers = false;
            }

            if (enabledNonDTPaymentServices && paymentServices.digitalRetailingType === 'default' && ownerName !== 'Private Seller') {
                removeParams.push('defaultRate');
            }

            if (!!paymentDealerId && enableFBAPaymentCalc) {
                overridePaymentCalcParams.dealerId = paymentDealerId;
                overridePaymentCalcParams.defaultRate = false;
                overrideIncentivesParams.dealerId = paymentDealerId;
            }

            if (!!paymentPartnerId && enableFBAPaymentCalc) {
                overridePaymentCalcParams.partnerId = paymentPartnerId;
                overrideIncentivesParams.partnerId = paymentPartnerId;
            }

            const updatedPSUrl = overridePSUrlParameters(paymentServices.paymentCalculationUrl, overridePaymentCalcParams, removeParams, forceDefaultRates);

            const updatedIncentivesUrl = overridePSUrlParameters(paymentServices.incentivesUrl, overrideIncentivesParams, [], forceDefaultRates);

            const updatedPaymentServices = deepMerge(paymentServices, {
                dealerSettings: { leaseEnabled, priceBreakdownEnabled },
                paymentCalculationUrl: updatedPSUrl,
                ...(!!paymentServices.incentivesUrl && { incentivesUrl: updatedIncentivesUrl }),
                digitalRetailingType,
            });

            return inventoryDuck.creators.updateInventoryItem(id, {
                paymentServices: updatedPaymentServices,
            });
        });

        batch(() => {
            inventoryUpdateActions.forEach((action) => {
                ctx.store.dispatch(action);
            });
        });
    };
}
