// Override hideEmailForm based on brand configuration
import { batch } from 'react-redux';

import { ownersDuck } from '@/ducks';

export default function withPaymentServices() {
    return async (ctx) => {
        const {
            email: [, { overrideHideEmail }],
        } = ctx.useFeatures(['email']);

        if (overrideHideEmail) {
            const state = ctx.store.getState();
            const owners = ownersDuck.selectors.getOwners(state);

            const ownerUpdateActions = Object.values(owners).map(({ id } = {}) => ownersDuck.creators.updateOwner(id, { hideEmailForm: false }));

            batch(() => {
                ownerUpdateActions.forEach((action) => {
                    ctx.store.dispatch(action);
                });
            });
        }
    };
}
