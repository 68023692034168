import _get from 'lodash/get';

import { getDealerDetailUrl } from './ddpUrlBuilder';

export default (alphaOwner) => {
    const ownerWebsiteLink = _get(alphaOwner, 'website.href', '');

    try {
        const ownerUrl = new URL(ownerWebsiteLink);

        if (ownerUrl.searchParams.get('utm_campaign') !== 'autotrader_alpha') {
            ownerUrl.searchParams.append('utm_campaign', 'autotrader_alpha');
            return ownerUrl.href;
        }
    } catch {
        return _get(getDealerDetailUrl(alphaOwner), 'href', '');
    }

    return ownerWebsiteLink;
};
