import { fetchJSON } from '@bonnet/next/fetch';

import { objectDuckCreator } from '@atc/modular-redux';

import { vdpDuckPreset } from '@/ducks/presets';

const vdpModelInfoDuck = objectDuckCreator({
    ...vdpDuckPreset,
    store: 'modelInfo',
    initialState: {},
}).extend({
    creators: () => ({
        loadModelInfo: ({ styleId }) => async (dispatch) => {
            const {
                features = {},
                title = '',
            } = await fetchJSON(`/rest/lsc/modelinfo/${styleId}`, {
                circuitBreaker: {
                    id: 'lsc_moduleinfo',
                    timeout: 3000,
                    resetTimeout: 30000,
                    fallback: {},
                },
                headers: {
                    'X-fwd-svc': 'atc',
                },
            });
            await dispatch(vdpModelInfoDuck?.creators?.setKeys({
                features,
                title,
            }));
        },
    }),
});

export default vdpModelInfoDuck;
