import { useEffect } from 'react';

import { smoothScroll } from 'atc-js';

export default function useHashScroll({
    delay = 0,
    enableSmoothScroll = false,
    offset = 0,
} = {}) {
    useEffect(() => {
        const scrollElement = window.location.hash || '';
        if (scrollElement) {
            const id = scrollElement.replace('#', '');
            setTimeout(() => {
                /* Giving couple of seconds buffer so the components below the fold can load */
                const element = document.getElementById(id);
                if (element) {
                    if (enableSmoothScroll) {
                        smoothScroll(id, {
                            offset,
                            onAfterScroll: () => {
                                // try again, in case the page height changed from lazy load.
                                setTimeout(() => {
                                    smoothScroll(id, { offset });
                                }, delay);
                            },
                        });
                    } else {
                        const elementRectangle = element.getBoundingClientRect();
                        if (elementRectangle) {
                            window.scrollTo({
                                top: elementRectangle.top + offset,
                                left: 0,
                                behavior: 'auto',
                            });
                        }
                    }
                }
            }, delay);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
