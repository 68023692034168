import {
    query as searchQuery,
} from './QueryStringUtil';
import useCookie from './useCookie';

/* set paid search cookies from the psid & siomid request parameters if they are defined */
export function usePaidSearchCookies() {
    const { osid, psid, siomid } = searchQuery() || {};
    const options = {
        maxAge: 1800, // 30 min
    };

    useCookie('osid', osid);
    useCookie('psid', psid, options, true);
    useCookie('siomid', siomid, options, true);
}

export default usePaidSearchCookies;
