import _get from 'lodash/get';

import {
    getScaledPrimaryImg,
} from '@/utilities';

import { pageNames } from '@atc/bonnet-paths';

import getSRPPrimaryImagesFromCtx from '@/utilities/getSRPPrimaryImagesFromCtx';

const { VEHICLE_DETAILS, SEARCH_RESULTS } = pageNames;

/* preload(http2 server push) the primary image */
export default function serverPushKeyRequests() {

    return async (ctx) => {
        if (typeof window === 'undefined') {

            const {
                data,
                store,
                res,
            } = ctx;
            const { browser } = store.getState();

            let scaledPrimaryImage;

            if (ctx.pageName === VEHICLE_DETAILS) {
                const { images } = data;
                const primary = _get(images, 'primary', 0);
                const primaryImgSrc = _get(images, `sources[${primary}].src`, '');
                if (primaryImgSrc) {
                    scaledPrimaryImage = getScaledPrimaryImg(VEHICLE_DETAILS, browser, primaryImgSrc);
                }
            } else if (ctx.pageName === SEARCH_RESULTS) {
                const primaryImages = getSRPPrimaryImagesFromCtx(ctx);
                const primaryImageSrc = _get(primaryImages, 'sources[0].src');

                if (primaryImageSrc) {
                    scaledPrimaryImage = getScaledPrimaryImg(SEARCH_RESULTS, browser, primaryImageSrc);
                }
            }

            const preloads = [];

            if (scaledPrimaryImage) {
                preloads.push(`<${scaledPrimaryImage}>; rel=preload; as=image`);
            }

            // TODO: BONNET NEXT - should Link be uppercase?
            res.setHeader('Link', preloads);
        }
    };
}
